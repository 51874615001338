<template>
  <div class="pageContol listWrap lecturerQualificationDownload">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">讲师资质下载</a>
        </span>
      </div>
      <div class="framePage-body">
        <div>
          <el-row :gutter="20" class="mabtm">
            <el-col :sm="12" :md="8" :lg="6" class="searchboxItem">
              <span class="itemLabel">课程名称：</span>
              <el-input
                size="small"
                v-model="courseName"
                type="text"
                placeholder="请输入课程名称"
                clearable
              />
            </el-col>
            <el-col :sm="12" :md="8" :lg="6" class="searchboxItem">
              <span class="itemLabel" style="width:170px;">资源归属机构：</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
                style="width:100%"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :sm="12" :md="6" :lg="6" class="df searchboxItem">
              <el-button type="primary" size="small" class="bgc-bv" round @click="getData()">查询</el-button>
            </el-col>
          </el-row>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />

              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                fixed
                prop="courseName"
                minWidth="300px"
              />
              <el-table-column
                label="资源归属机构"
                align="left"
                show-overflow-tooltip
                prop="compOwnerName"
                minWidth="200px"
              />
              <el-table-column
                label="学时"
                align="right"
                show-overflow-tooltip
                prop="lessonNum"
                minWidth="100px"
              />
              <el-table-column
                label="视频总时长"
                align="right"
                show-overflow-tooltip
                prop="totalDuration"
                minWidth="100px"
              >
                <template slot-scope="scope">{{ getTime(scope.row.totalDuration)}}</template>
              </el-table-column>
              <el-table-column
                label="创建时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                minWidth="200px"
              >
                <template slot-scope="scope">{{scope.row.createTime|moment}}</template>
              </el-table-column>

              <el-table-column label="操作" align="center" minWidth="100px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="confirmDocsDialog(scope.row.courseId,scope.row.courseName)"
                  >讲师资质下载</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/lecturerQualificationDownloadList",
  components: {
    Empty,
    PageNum
  },
  data() {
    return {
      CompanyList: [], // 资源提供者
      compId: "", // 资源提供者
      courseName: ""
    };
  },
  mixins: [List],
  created: function() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser"
    })
  },
  watch: {
    compId: function(val) {
      if (val == "") {
        this.CompanyList = [];
      }
    }
  },
  methods: {
    // 资源提供者
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.compId) {
        console.log(this.CompanyList);
        this.CompanyList.forEach((el, index) => {
          if ((this.compId == el.compId)) {
            params.compOwnerName = el.compName;
          }
        });
      }
      if (this.courseName) {
        params.courseName = this.courseName;
      }

      this.doFetch({
        url: "/run/project/getTeacherQualificationByProjectId ",
        params,
        pageNum
      });
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    confirmDocsDialog(courseId, courseName) {
        this.$confirm('请选择讲师资质是否增加水印', "提示", {
            confirmButtonText: "带水印",
            cancelButtonText: "无水印",
            distinguishCancelAndClose: true,
            closeOnClickModal: false
        })
            .then(() => {
                this.$post("/sys/downloadTeacherQualification/asyncDownloadFile", {
                    courseId: courseId,
                    courseName: courseName,
                    waterMark: '10'

                }).then(re => {
                    if (re.status == "0") {
                        if (!this.downloadItems.includes(re.data.taskId)) {
                            this.$store.dispatch("pushDownloadItems", re.data.taskId);
                        } else {
                            this.$message.warning(
                                "[" + re.data.fileName + "]已经申请下载,请耐心等待"
                            );
                        }
                    } else {
                        this.$message.error(re.message);
                    }
                });
            })
            .catch((e) => {
                if(e == 'cancel'){
                    this.$post("/sys/downloadTeacherQualification/asyncDownloadFile", {
                        courseId: courseId,
                        courseName: courseName,
                        waterMark: '20'
                    }).then(re => {
                        if (re.status == "0") {
                            if (!this.downloadItems.includes(re.data.taskId)) {
                                this.$store.dispatch("pushDownloadItems", re.data.taskId);
                            } else {
                                this.$message.warning(
                                    "[" + re.data.fileName + "]已经申请下载,请耐心等待"
                                );
                            }
                        } else {
                            this.$message.error(re.message);
                        }
                    });
                }else{
                    return
                }
            });

    }
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>

<style lang="less" scoped>
.lecturerQualificationDownload {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mabtm {
    padding-bottom: 0.675rem;
    border-bottom: 1px solid #f5f5f6;
  }
  .searchboxItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    .bgc-bv {
      width: 72px;
    }
    .el-cascader {
      height: 32px !important;
    }
    .itemLabel {
      width: 155px;
      text-align: right;
    }
  }
}
</style>
